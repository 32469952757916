<template>
  <div class="row">
    <loading-notification :show="loading" />
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('teams.edit')">
        <actions
          slot="actions"
          crud-links="teams"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <tabs-container :tabs="tabs">
          <template v-slot:data>
            <data-form
              :team="team"
              :loading="loading"
              @submit="submit"
            />
          </template>
          <template v-slot:members>
            <members-table
              :edit="true"
              :team="team"
              :loading="loading"
              @submit="submit"
            />
          </template>
        </tabs-container>
      </va-card>
    </div>
  </div>
</template>

<script>
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const MembersTable = () => import(/* webpackPrefetch: true */ './Members')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'teams-edit',
  components: {
    TabsContainer,
    Actions,
    DataForm,
    MembersTable,
  },
  data () {
    return {
      actions: ['index', 'view', 'delete'],
      error: false,
      loading: false,
      team: {},
    }
  },
  computed: {
    tabs () {
      const t = [
        { title: this.$t('teams.tabs.data.title'), name: 'data' },
        { title: this.$t('teams.tabs.members.title'), name: 'members' },
      ]

      return t
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `teams/${id}`
    },
    routeBuilder2 (id) {
      return `/teams/equipments/update/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const teamId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(teamId))
      } catch (err) {
        // console.log('Error fetching team data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.team = u.data.data
    },
    async submit (team) {
      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(team.id), team)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      const id = team.teams_year_plans[0].id
      try {
        await this.$http.post(this.routeBuilder2(id), team.teams_year_plans[0])
      } catch (e) {
        console.log('History error', e)
        this.loading = false
        return
      }

      if (team.users && team.users._ids) return this.teamHistory(team)

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
    async teamHistory (team) {
      const data = {
        originals: [],
        currents: team.users._ids,
      }

      this.team.users.forEach(m => {
        data.originals.push(m.id)
      })

      try {
        await this.$http.post('teams/history/' + this.team.id, data)
      } catch (e) {
        // console.log('History error', e)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
